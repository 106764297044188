<template>
	<div class="box_first">
		<div class="login_box">
			<div class="login_title">
				<img src="../../assets/login/login_name.png" alt="" style="width: 2.2rem;height: auto;"/>
			</div>
			<el-input class='input_box' v-model="username" size="large" placeholder="请输入账号">
				<i slot="prefix" class="el-input__icon el-icon-user-solid"
					style="font-size: 0.24rem;color: #0148A1;"></i>
			</el-input>
			<el-input class='input_box' v-model="password" size="large" type="password" placeholder="请输入密码">
				<i slot="prefix" class="el-input__icon el-icon-lock"
					style="font-size: 0.24rem;color: #0148A1;"></i>
			</el-input>
			<div style="display: flex;padding-left: 10px;margin-top: 20px;">
				<el-checkbox v-model="remenber">自动登录</el-checkbox>
			</div>

			<el-button class="btn" type="primary" size="large" @click="login">登录</el-button>

		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				remenber: true,
				isyzmlogin: false, //是否为验证码登录
				timer: 0, // 倒计时计时器
				intervalId: null, // 存储setInterval的ID
				username: '',
				password: '',
				code:'',
			}
		},
		mounted() {
			document.title = '西宁市城北区人民检察院公文管理系统';
		},
		created() {
			let luser = JSON.parse(localStorage.getItem('userinfo'))
			if (luser && luser.token && luser.menu.length >= 1) {
				let pathurl = this.findLastUrlNode(luser.menu)
				this.$store.commit('setUserInfo', luser)
				this.$router.push(pathurl.url)
				
			}
		},
		methods: {
			login() {
				let info = {
					id: "",
					username: "",
					token: "",
					menu: '',
					rytype:1
				}
				
				if(this.isyzmlogin){//短信登录
					if (!this.code) {
						this.$message.warning('请输入验证码！');
						return false
					}
					this.$post({
						url: '/api/login/mobile_login',
						params: {
							username: this.username,
							code: this.code
						}
					}).then((res) => {
						info.menu = res.menu
						info.rytype = res.rytype
						let pathurl = this.findLastUrlNode(res.menu)
						info.token = res.token
						info.username = this.username
						localStorage.setItem('userinfo', JSON.stringify(info)); //本地缓存
						this.$store.commit('setUserInfo', info)					
						this.$router.push(pathurl.url)
					})
					
					
				}else{//账号密码登录
					if (!this.username || !this.password) {
						this.$message.warning('请输入账号或密码！');
						return false
					}
					this.$post({
						url: '/api/login/login',
						params: {
							username: this.username,
							password: this.password,
							type: 2
						}
					}).then((res) => {
						info.menu = res.menu
						info.rytype = res.rytype
						let pathurl = this.findLastUrlNode(res.menu)
						info.token = res.token
						info.username = this.username
						localStorage.setItem('userinfo', JSON.stringify(info)); //本地缓存
						this.$store.commit('setUserInfo', info)
						// if (this.remenber) {
						// 	localStorage.setItem('userinfo', JSON.stringify(res.userinfo)); //本地缓存
						// }
						// this.$store.commit('setUserInfo', res.userinfo)
					
						this.$router.push(pathurl.url)
					})
				}
			},
			sendCode() {
				if (!this.username || this.username.length!=11) {
					this.$message.warning('请输入正确的手机号！');
					return
				}
				if (this.timer > 0) {
					this.$message.warning('请在倒计时结束后再发送')
					return; // 如果当前计时器已经在倒计时，则不进行操作
				}
				this.$post({
					url: '/api/sms/send',
					params: {
						mobile: this.username,
						event: 'login'
					}
				}).then((res) => {
					this.$message.success('验证码已发送！');
					this.startCountdown()
				})
				
			},
			startCountdown() {
				this.timer = 60; // 设置倒计时时长
				this.intervalId = setInterval(() => {
					if (this.timer > 0) {
						this.timer--;
					} else {
						clearInterval(this.intervalId); // 倒计时结束，清除计时器
						this.intervalId = null;
					}
				}, 1000);
			},
			findLastUrlNode(data) {
				let nearestNode = null;
				for (let i = 0; i < data.length; i++) {
					const node = data[i];
					if (node.url) {
						nearestNode = node;
						break; // 找到第一个具有值的URL即可停止搜索
					}
					if (node.children) {
						const childNode = this.findLastUrlNode(node.children);
						if (childNode) {
							nearestNode = childNode;
							break; // 如果子节点找到了具有值的URL，也停止搜索
						}
					}
				}

				return nearestNode;
			}
		},
		beforeDestroy() {
			// 组件销毁之前清除计时器
			if (this.intervalId !== null) {
				clearInterval(this.intervalId);
			}
		}
	}
</script>

<style lang="scss" scoped>
	.box_first {
			box-sizing: border-box;
			width: 100vw;
			height: 100vh;
			background-image: url('../../assets/login/login_bg.png');
			background-size: 100% 100%;
			background-repeat: no-repeat;
			display: flex;
			align-items: center;
			justify-content: center;
	
			.login_box {
				width: fit-content;
				background-image: url('../../assets/login/login_title.png');
				background-repeat: no-repeat;
				background-size: 100% 100%;
				padding: 11vw;
				display: flex;
				flex-direction: column;
				padding-left: 61vw;
				padding-right: 9.5vw;
	
				.input_box {
					width: 19vw;
					margin: 1vh;
				}
	
				.btn {
					margin-top: 10vh;
					font-size: 0.2rem;
				}
	
				.login_title {
					font-size: 0.4rem;
					font-weight: bold;
					text-align: center;
					margin-bottom: 8vh;
					color: #409eff;
				}
	
			}
		}
</style>